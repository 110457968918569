<template>
  <v-row wrap align="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-list-item-content>
        <v-list-item-title class="text-justify"
          >{{ course.title }}
          <a :href="course.url" target="_blank" rel="noopener" class="ma-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              width="20"
              viewBox="0 0 30 30"
              fill="#f4f4f4"
              stroke="#f4f4f4"
              stroke-width="1"
            >
              <path
                d="M5.625 20q-.7 0-1.162-.462Q4 19.075 4 18.375V5.625q0-.7.463-1.162Q4.925 4 5.625 4h5.6v1h-5.6q-.25 0-.437.188Q5 5.375 5 5.625v12.75q0 .25.188.437.187.188.437.188h12.75q.25 0 .437-.188.188-.187.188-.437v-5.6h1v5.6q0 .7-.462 1.163-.463.462-1.163.462Zm4.125-5.025-.725-.725L18.3 5H14V4h6v6h-1V5.7Z"
              /></svg
          ></a>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ course.semester }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ course.role }}
        </v-list-item-subtitle>
      </v-list-item-content></v-col
    ></v-row
  >
</template>

<script>
export default {
  components: {},
  props: ["course"],
  data() {
    return {};
  },
  created() {
  },

  methods: {},
};
</script>
