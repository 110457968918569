<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-main>
      <Navbar />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
// import styles from './styles.css'

export default {
  name: "App",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
        dp_size: "sm",
      },
      isDark: true,
      currentTheme: "light",
    };
  },
  components: {
    Navbar,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth * 0.9;
      this.window.height = window.innerHeight * 0.8;
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>
<style></style>
