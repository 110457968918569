<template>
  <v-layout align-center justify-center>
    <v-card tile color="primary" width="1000">
      <h1 class="primary_text--text ma-2 px-2">Publications</h1>
      <v-container>
        <div v-for="currentYear in this.groupedPapers" :key="currentYear.year">
          {{ currentYear.year }}
          <v-list-item
            three-line
            v-for="(paper, i) in currentYear.items"
            :key="i"
          >
            <ResearchPaper :paper="paper"></ResearchPaper>
          </v-list-item>
        </div>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
import ResearchPaper from "../components/ResearchPaper";
export default {
  components: { ResearchPaper },
  data() {
    return {
      open: true,
      papers: {},
      groupedPapers: {},
      posts: {},
    };
  },

  methods: {
    handleResize() {
      this.window.width = window.innerWidth * 0.8;
      this.window.height = window.innerHeight * 0.8;
    },
    next(url) {
      window.open(url);
    },
    groupItems() {
      const result = [];
      let currentYear = null;
      for (const item of this.papers) {
        if (item.year !== currentYear) {
          currentYear = item.year;
          result.push({ year: currentYear, items: [item] });
        } else {
          result[result.length - 1].items.push(item);
        }
      }
      return result;
    },
  },
  computed: {},
  created() {
    this.$http
      .get(`/api/publications`, {})
      .then((response) => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
        this.papers = this.posts["docs"];
        this.papers = this.papers.filter((i) => i.show);
        this.groupedPapers = this.groupItems();
      })
      .catch((e) => {
        console.log(e);
      });
  },
  mounted() {
    this.dp_size = this.$vuetify.breakpoint.name;
  },
};
</script>

<style scoped>
* /deep/ .v-list-item__title {
  white-space: normal;
}
* /deep/ .v-list-item__subtitle {
  white-space: normal;
}
@keyframes strokeAni {
  from {
    fill: white;
  }
  to {
    fill: #00bcd4;
  }
}
a:hover svg {
  fill: #00bcd4;
  animation: strokeAni 10ms ease-in-out 10ms forwards;
}
</style>
