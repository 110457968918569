<template>
  <v-layout align-center justify-center>
    <v-card tile color="primary" width="1000">
      <h1 class="primary_text--text ma-2 px-2">Teaching</h1>
      <v-container>
        <div
          v-for="currentSem in this.groupedCourses"
          :key="currentSem.semester"
        >
          <v-list-item-title>{{ currentSem.semester }}</v-list-item-title>

          <v-list-item
            three-line
            v-for="(course, i) in currentSem.items"
            :key="i"
          >
            <TeachingCourse :course="course"></TeachingCourse>
          </v-list-item>
        </div>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
import TeachingCourse from "../components/TeachingCourse";
export default {
  data() {
    return {
      components: { TeachingCourse },
      open: true,
      courses: [],
      groupedCourses: {},
      posts: {},
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth * 0.8;
      this.window.height = window.innerHeight * 0.8;
    },
    next(url) {
      window.open(url);
    },
    extractNumericSemester(semester) {
      const matches = semester.match(/\d+/g);
      if (matches) {
        return parseInt(matches[0]);
      }
      return 0;
    },
    groupItems() {
      const sortedCourses = this.courses.slice().sort((a, b) => {
        const semesterA = this.extractNumericSemester(a.semester);
        const semesterB = this.extractNumericSemester(b.semester);
        return semesterA - semesterB;
      });
      const result = [];
      let currentSemester = null;
      for (const item of sortedCourses) {
        if (item.semester !== currentSemester) {
          currentSemester = item.semester;
          result.push({ semester: currentSemester, items: [item] });
        } else {
          result[result.length - 1].items.push(item);
        }
      }

      result.sort((a, b) => {
        const semesterA = this.extractNumericSemester(a.semester);
        const semesterB = this.extractNumericSemester(b.semester);
        return semesterA - semesterB;
      });
      result.reverse();
      return result;
    },
  },
  created() {
    this.$http
      .get(`/api/courses?limit=10000`)
      .then((response) => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
        this.courses = this.posts["docs"];
        this.groupedCourses = this.groupItems();
      })
      .catch((e) => {
        console.log(e);
      });
  },
  mounted() {
    this.dp_size = this.$vuetify.breakpoint.name;
  },
  components: { TeachingCourse },
};
</script>

<style scoped>
* /deep/ .v-list-item__title {
  white-space: normal;
}
* /deep/ .v-list-item__subtitle {
  white-space: normal;
}
@keyframes strokeAni {
  from {
    stroke: white;
  }
  to {
    stroke: #00bcd4;
    stroke-width: 2px;
  }
}
a:hover svg {
  stroke: white;
  fill: none;
  stroke-width: 2px;
  animation: strokeAni 10ms ease-in-out 10ms forwards;
}
</style>
