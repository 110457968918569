<template>
  <v-main v-if="apiReachable">
    <Me id="me"></Me>
    <br />
    <Research id="research"></Research>
    <br />
    <Teaching id="teaching"></Teaching>
    <br />
    <AdvisedTheses id="theses"></AdvisedTheses>
    <br />
  </v-main>
  <v-main v-else-if="isLoading" class="loadingContainer">
    <div class="loading"></div
  ></v-main>
  <v-main class="maintenance" v-else>Down for Maintenance!</v-main>
</template>

<script>
import Me from "@/views/Me.vue";
import Research from "@/views/Research.vue";
import Teaching from "@/views/Teaching.vue";
import AdvisedTheses from "@/views/AdvisedTheses.vue";

export default {
  name: "Home",
  components: { Me, Research, Teaching, AdvisedTheses},
  data() {
    return {
      apiReachable: false,
      isLoading: true,
    };
  },
  mounted() {
    this.$http
      .get(`/api/socials`)
      .then((response) => {
        if (response.status === 200) {
          this.apiReachable = true;
          this.isLoading = false;
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        this;
      });
  },
};
</script>
<style>
.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* Set the height to 100% of the viewport height */
  text-align: center;
  font-size: 20pt;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-justify: center;
  justify-items: center;
}
.loading {
  display: flex;
  height: 3.5em; /* Set the height to 100% of the viewport height */
  width: 3.5em;
  margin: auto;
  border: 3px solid transparent;
  border-top-color: #3cefff;
  border-bottom-color: #3cefff;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.loading:before {
  content: "";
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 0.75em;
  height: 0.75em;
  border: 3px solid #00bcd4;
  border-radius: 50%;
  animation: pulse 1s alternate ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes strokeAni {
  from {
    stroke: white;
  }
  to {
    stroke: #00bcd4;
    stroke-width: 3px;

  }
}
@keyframes strokeAniFill {
  from {
    stroke: white;
  }
  to {
    stroke: #00bcd4;
    stroke-width: 3px;

  }
}
.outlined:hover svg {
  stroke: white;
  fill: none;
  stroke-width: 2px;
  animation: strokeAni 10ms ease-in-out 10ms forwards;
}
.filled:hover svg {
  stroke: white;
  fill: #00bcd4;
  stroke-width: 2px;
  animation: strokeAniFill 10ms ease-in-out 10ms forwards;
}

</style>
