<template>
  <div align="center" justify="center">
    <v-img class="rounded-circle" max-width="250px" lazy-src v-bind:src="imageUrl"></v-img>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["imgurl"],
  data() {
    return {
      imageUrl: "",
    };
  },
  created() {
    this.getImage(this.imgurl);
  },

  methods: {
    async getImage(src) {
      // try {
      // Make a GET request to the API
      const response = await this.$http.get(src, {
        responseType: "arraybuffer",
      });

      // Convert the array buffer to a base64-encoded string
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      // Set the image URL to the base64-encoded string
      this.imageUrl = `data:${response.headers["content-type"]};base64,${base64}`;
    },
  },
};
</script>
