<template>
  <nav color="dark_primary">
    <v-app-bar app color="dark_primary">
      <router-link style="text-decoration: none; color: inherit" :to="'/'">
        <v-toolbar-title app>
          <span class="accent--text">jan</span>
          <span class="dark_text--text">leusmann</span>
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <div class="navbar">
        <a class="dark_text--text" @click="scrollToTop('me')">Me</a>
        <a class="dark_text--text" @click="scrollToTop('research')">Publications</a>
        <a class="dark_text--text" @click="scrollToTop('teaching')">Teaching</a>
        <a class="dark_text--text" @click="scrollToTop('theses')">Theses</a>
      </div>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isDesktop: true,
      drawer: false,
      isDark: false,
      links: [
        { icon: "mdi-home", text: "Home", route: "/" },
        // { icon: "mdi-music", text: "Music", route: "/Music" },
        // { icon: "mdi-book", text: "Research", route: "/Research" }
      ],
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {},

  methods: {
    scrollToTop(targetComponent) {
      const targetElement = document.getElementById(targetComponent);
      if (targetElement) {
        window.scrollBy({ top: targetElement.getBoundingClientRect()['y']-80, behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
.footer {
  position: relative;
  bottom: 0px;
  width: 100%;
  background: #d4d4d4;
}

button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
button:hover {
  opacity: 0.5;
}
.navbar {
  color: white; /* Set text color to white */
  padding: 10px;
}
.navbar a {
  color: white; /* Set link text color to white */
  text-decoration: none;
  margin: 0 10px; /* Add some spacing between links */
}
@media (min-width: 769px) {
  .navbar {
    display: block; /* Show the navigation links on desktop */
  }
}

/* Add styles for mobile view (screen width 768px or less) */
@media (max-width: 768px) {
  .navbar {
    display: none; /* Hide the navigation links on mobile */
  }
}
</style>
