import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Research from "@/views/Research.vue";
import Teaching from "@/views/Teaching.vue";
import AdvisedTheses from "@/views/AdvisedTheses.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Home }, // Define your components and their corresponding routes
    { path: '/publications', component: Research },
    { path: '/teaching', component: Teaching },
    { path: '/theses', component: AdvisedTheses },
  ],
});

export default router;
