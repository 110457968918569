import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import vuetify from "./plugins/vuetify";
import VueClipboard from "vue-clipboard2";
import axios from "axios";
import VueRouter from "vue-router";

// Create an instance of axios with a custom configuration
const instance = axios.create({
  baseURL: "https://payload.leusmann.io",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to add the authorization header to all requests
instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `User API-Key c50456e9-4913-4620-a484-dc87f8882322`;
    return config;
  },
  (error) => Promise.reject(error)
);

// Use the custom instance for all axios requests
Vue.prototype.$http = instance;

// axios.defaults.withCredentials = true

import "vuetify/dist/vuetify.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

Vue.config.productionTip = false;

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  store,
  vuetify,
  // apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
