<template>
  <div>
    <v-dialog v-model="dialog" max-width="900" id="AbstractDialog">
      <template v-slot:activator="{ on, attrs }">
        <button
          class="text-decoration-underline primary_text--text"
          subtitle-2
          text
          dark
          v-bind="attrs"
          v-on="on"
        >
          Abstract
        </button>
        <button
          class="text-decoration-underline primary_text--text mx-2"
          subtitle-2
          text
          dark
          v-clipboard:copy="paper.bibtex"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          {{ snackbarContent }}
        </button>
      </template>

      <v-card>
        <v-card-title>
          {{ paper.title }}
        </v-card-title>

        <v-card-text>
          <div class="abstract-img">
            <v-img lazy-src contain v-bind:src="imageUrl"></v-img>
          </div>
          <br />
          <div class="abstract-text">
            {{ paper.abstract }}
          </div>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <a :href="paper.url" target="_blank" rel="noopener" class="ma-2">
            <v-btn class="accent--text" text>Read Paper</v-btn></a
          >
          <v-btn class="accent--text" text @click="dialog = false">
            Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="showPopup" class="popup">
      <v-card>Copied to clipboard</v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["paper"],
  data() {
    return {
      dialog: false,
      showPopup: false,
      snackbarContent: "Copy Bibtex!",
      timeout: 2000,
      imageUrl: "",
    };
  },
  created() {
    this.getImage(this.paper.img.url);
  },
  methods: {
    onCopy: function (e) {
      this.snackbarContent = "Copied!";
      setTimeout(() => {
        this.snackbarContent = "Copy Bibtex!";
      }, 2000); // 2000 milliseconds (2 seconds)
    },
    onError: function (e) {
      this.snackbarContent = "Error copying!";
      this.snackbar = true;
    },
    async getImage(src) {
      // try {
      // Make a GET request to the API
      const response = await this.$http.get(src, {
        responseType: "arraybuffer",
      });

      // Convert the array buffer to a base64-encoded string
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      // Set the image URL to the base64-encoded string
      this.imageUrl = `data:${response.headers["content-type"]};base64,${base64}`;
    },
  },
};
</script>

<style>
.abstract-text {
  text-align: justify;
  /* padding: 210 20 20 20; */
}
.abstract-img {
  text-align: center;
  display: flex;
  justify-content: center;
}
.popup {
  position: absolute;
  top: 70px; /* Adjust the top position as needed */
  left: 160px; /* Adjust the left position as needed */
  /* background-color: #fff; */
  /* border: 1px solid #ccc; */
  padding: 10px;
  z-index: 1000;
  /* Add more styling as desired */

  /* CSS for the fade-out effect */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.popup.fade-out {
  opacity: 0;
}
</style>
