<template>
  <v-layout align-center justify-center>
    <v-card tile color="primary" width="1000">
      <h1 class="primary_text--text ma-2 px-2">Advised Theses</h1>
      <v-container>
        <div v-for="currentYear in this.groupedTheses" :key="currentYear.year">
          <v-list-item-title>{{ currentYear.year }}</v-list-item-title>

          <v-list-item
            three-line
            v-for="(thesis, i) in currentYear.items"
            :key="i"
          >
            <AdvisedThesis :thesis="thesis"></AdvisedThesis>
          </v-list-item>
        </div>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
import AdvisedThesis from "../components/AdvisedThesis";
export default {
  data() {
    return {
      components: { AdvisedThesis },
      open: true,
      theses: [],
      groupedTheses: {},
      posts: {},
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth * 0.8;
      this.window.height = window.innerHeight * 0.8;
    },
    next(url) {
      window.open(url);
    },
    groupItems() {
      // First, sort the items by year in descending order
      const sortedItems = this.theses.slice().sort((a, b) => b.year - a.year);
      console.log(sortedItems);
      const result = [];
      let currentYear = null;
      let currentGroup = null;
      for (const item of sortedItems) {
        if (item.show == false) {
          continue;
        }
        console.log(item);
        if (item.year !== currentYear) {
          currentYear = item.year;
          currentGroup = { year: currentYear, items: [item] };
          result.push(currentGroup);
        } else {
          currentGroup.items.push(item);
        }
      }

      return result;
    },
  },
  created() {
    this.$http
      .get(`/api/advisedthesis?limit=10000`)
      .then((response) => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
        this.theses = this.posts["docs"];
        this.groupedTheses = this.groupItems();
      })
      .catch((e) => {
        console.log(e);
      });
  },
  mounted() {
    this.dp_size = this.$vuetify.breakpoint.name;
  },
  components: { AdvisedThesis },
};
</script>

<style scoped>
* /deep/ .v-list-item__title {
  white-space: normal;
}
* /deep/ .v-list-item__subtitle {
  white-space: normal;
}
@keyframes strokeAni {
  from {
    stroke: white;
  }
  to {
    stroke: #00bcd4;
    stroke-width: 2px;
  }
}
a:hover svg {
  stroke: white;
  fill: none;
  stroke-width: 2px;
  animation: strokeAni 10ms ease-in-out 10ms forwards;
}
</style>
