<template>
  <div>
    {{ content.content }}
    <p></p>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["content"],
  data() {
    return {};
  },
  created() {},

  methods: {},
};
</script>
