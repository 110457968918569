<template>
  <a :href="social.url" target="_blank" rel="noopener" class="ma-2" :class="social.class">
    <span v-html="richText"></span>
  </a>
</template>
<script>
// import SVGInject from "svg-inject";
export default {
  components: {},
  props: ["social"],
  data() {
    return {
      iconUrl: "",
      richText: "",
    };
  },
  created() {
    this.getRichText(this.social.inlinesvg);
  },

  methods: {
    async getImage(src) {
      // try {
      // Make a GET request to the API
      const response = await this.$http.get(src, {
        responseType: "arraybuffer",
      });

      // Convert the array buffer to a base64-encoded string
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      // Set the image URL to the base64-encoded string
      this.iconUrl = `data:${response.headers["content-type"]};base64,${base64}`;
    },
    getRichText(src) {
      this.richText = src[0].children[0].text;
    },
  },
};
</script>

<style scoped>
/* .my-svg-component {
  max-width: 24px;
}
@keyframes strokeAni {
  from {
    stroke: white;
  }
  to {
    stroke: #00bcd4;
    stroke-width: 3px;
  }
}
span:hover svg {
  stroke: white;
  fill: none;
  stroke-width: 2px;
  animation: strokeAni 10ms ease-in-out 10ms forwards;
} */
</style>
