<template>
  <v-layout align-center justify-center>
    <v-card tile color="primary" width="1000">
      <h1 class="primary_text--text ma-2 px-2">Me</h1>
      <v-container>
        <v-row>
          <v-col>
            <div class="text-justify mx-5">
              <div v-for="(content, i) in this.contents" :key="i">
                <MeContent :content="content"></MeContent>
              </div>
            </div>
          </v-col>
          <v-col
            class="text-justify align-center justify-center"
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >
            <MePicture :imgurl="meurl"></MePicture>
          </v-col>
        </v-row>
        <br />
        <v-row class="text-center"
          ><v-col>
            <span v-for="(social, i) in this.socials" :key="i">
              <Social :social="social"></Social>
            </span> </v-col
        ></v-row>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
import MeContent from "../components/MeContent";
import MePicture from "../components/MePicture";
import Social from "../components/Social";
export default {
  name: "Me",
  data() {
    return {
      components: { MeContent, Social, MePicture },
      contents: {},
      socials: {},
      meurl: "https://payload.leusmann.io/media/Me-400x400.jpg",
    };
  },
  created() {
    this.$http
      .get(`/api/me`)
      .then((response) => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
        this.contents = this.posts["docs"];
      })
      .catch((e) => {
        console.log(e);
      });
    this.$http
      .get(`/api/socials`)
      .then((response) => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
        this.socials = this.posts["docs"];
      })
      .catch((e) => {
        console.log(e);
      });
  },
  components: { MeContent, Social, MePicture },
};
</script>

<style></style>
