<template>
  <div>
    <v-dialog v-model="dialog" max-width="900" id="AbstractDialog">
      <template v-slot:activator="{ on, attrs }">
        <button
          class="text-decoration-underline primary_text--text"
          subtitle-2
          text
          dark
          v-bind="attrs"
          v-on="on"
        >
          Abstract
        </button>
        Links: 
      </template>

      <v-card>
        <v-card-title>
          {{ paper.title }}
        </v-card-title>
        <!-- <v-divider></v-divider>
      <v-spacer></v-spacer> -->
        <v-card-text>
          {{ paper.abstract }}
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <a :href="paper.url" target="_blank" rel="noopener" class="ma-2">
            <v-btn class="accent--text" text>Read Paper</v-btn></a
          >
          <v-btn class="accent--text" text @click="dialog = false">
            Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      top
      absolute
      v-model="snackbar"
      :timeout="timeout"
      rounded="pill"
      centered
      class="align-center justify-center"
    >
      {{ snackbarContent }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="accent--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["paper"],
  data() {
    return {
      dialog: false,
      snackbar: false,
      snackbarContent: "Copied!",
      timeout: 2000,
    };
  },
  methods: {
    onCopy: function (e) {
      this.snackbarContent = "Copied!";
      this.snackbar = true;
      // alert("You just copied the following text to the clipboard: " + e.text);
    },
    onError: function (e) {
      this.snackbarContent = "Error copying!";
      this.snackbar = true;
    },
  },
};
</script>

<style scoped></style>
