<template>
  <v-row wrap align="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-list-item-content>
        <v-list-item-title class="text-justify"
          >{{ thesis.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ thesis.type }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Student: {{ thesis.student }}
        </v-list-item-subtitle>
      </v-list-item-content></v-col
    ></v-row
  >
</template>

<script>
export default {
  components: {},
  props: ["thesis"],
  data() {
    return {};
  },
  created() {

  },

  methods: {},
};
</script>
