import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentSeconds: 0,
        currentSong: {},
        songs: [],
        isPlaying: false,
        player: new Audio(),
        playlists: [],
    },
    mutations: {
        safeSeconds(state, seconds) {
            state.currentSeconds = seconds
        },
        SetIsPlaying(state, val) {
            state.isPlaying = val
        },
        SetCurrentSong(state, song) {
            state.currentSong = song
        },
        SetPlaylists(state, playlists){
            state.playlists = playlists;
        }
    }
})
